import React from 'react';
import { NativeSelect, Grid } from '@material-ui/core';
import { formatInTimeZone } from 'date-fns-tz';
import _ from 'lodash';
import PeripheralOptional from '../Optional';
import RangeTime from '../RangeTime';
import {
  Input,
  InputV2,
  InputPlus,
} from '../../../../../../../react-components';
import { parseNumber } from '../../helpers';
import localConfig from './config';
import './styles.scss';

interface CalculetedFlowProps {
  config: any;
  customHandleChange: any;
  errors: any;
  setErrors: any;
  handleSetTimezone: any;
  fieldsState: any;
  isVentConnected: boolean;
  onChangeState: (name: string, value: any) => void;
  validateInput: (fieldsStateValue: any, constraint: any, errors: any) => void;
}

const G3_STRINGS: any = { ...localConfig.strings.g3Controller };

const CalculatedFlow: React.FC<CalculetedFlowProps> = ({
  config,
  errors,
  setErrors,
  handleSetTimezone,
  fieldsState,
  customHandleChange,
  isVentConnected,
  onChangeState,
  validateInput,
}) => {
  const TIMEZONE: any = config?.timezone ? _.cloneDeep(config.timezone) : [];


  return (
    <div id="calculated-flow">
      <Grid item className="section-1 calc-mode">
        <div className="calc-mode--wrapper">
          <InputPlus
            handleChange={(value: any) => {
              onChangeState('fhfr', parseNumber(value));
            }}
            handleChangeError={(err: any) => {}}
            disabled={false}
            type={config.fields.fhfr.type}
            label={config.fields.fhfr.label}
            value={`${fieldsState?.fhfr}`}
            units={config.fields.fhfr.units}
            error={errors?.fhfr}
            name={'fhfr'}
          />
        </div>
      </Grid>
      <Grid item className="section-1 calc-mode">
        <div className="calc-mode--wrapper">
          <InputPlus
            handleChange={(value: any) => {
              onChangeState('fcfr', parseNumber(value));
            }}
            handleChangeError={(err: any) => {}}
            disabled={false}
            type={config.fields.fcfr.type}
            label={config.fields.fcfr.label}
            value={`${fieldsState?.fcfr}`}
            units={config.fields.fcfr.units}
            error={errors?.fcfr}
            name={'fcfr'}
          />
        </div>
      </Grid>
      <Grid item className="section-1 calc-mode">
        <div className="calc-mode--wrapper">
          <InputPlus
            handleChange={(value: any) => {
              onChangeState('fffr', parseNumber(value));
            }}
            handleChangeError={(err: any) => {}}
            disabled={false}
            type={config.fields.fffr.type}
            label={config.fields.fffr.label}
            value={`${fieldsState?.fffr}`}
            units={config.fields.fffr.units}
            error={errors?.fffr}
            name={'fffr'}
          />
        </div>
      </Grid>
      <Grid item className="section-1 calc-mode">
        <div className="calc-mode--wrapper">
          <InputPlus
            handleChange={(value: any) => {
              onChangeState('carq', parseNumber(value));
            }}
            handleChangeError={(err: any) => {}}
            disabled={false}
            type={config.fields.carq.type}
            label={config.fields.carq.label}
            value={`${fieldsState?.carq}`}
            units={config.fields.carq.units}
            error={errors?.carq}
            name={'carq'}
          />
        </div>
      </Grid>
      {isVentConnected && (
        <>
          {fieldsState?.primary_control === 'fanConnect' && (
            <Grid item className="section-1 calc-mode">
              <div className="calc-mode--wrapper">
                <InputPlus
                  handleChange={(value: any) => {
                    onChangeState('effr', parseNumber(value));
                  }}
                  handleChangeError={(err: any) => {}}
                  disabled={false}
                  type={config.fields.effr.type}
                  label={config.fields.effr.label}
                  value={`${fieldsState?.effr}`}
                  units={config.fields.effr.units}
                  error={errors?.effr}
                  name={'effr'}
                />
              </div>
            </Grid>
          )}
          {fieldsState?.primary_control !== 'fanConnect' && (
            <Grid item className="section-1 calc-mode">
              <div className="calc-mode--wrapper">
                <InputPlus
                  handleChange={(value: any) => {
                    onChangeState('vcfr', parseNumber(value));
                  }}
                  handleChangeError={(err: any) => {}}
                  disabled={false}
                  type={config.fields.vcfr.type}
                  label={config.fields.vcfr.label}
                  value={`${fieldsState?.vcfr}`}
                  units={config.fields.vcfr.units}
                  error={errors?.vcfr}
                  name={'vcfr'}
                />
              </div>
            </Grid>
          )}
        </>
      )}

      {isVentConnected && (
        <>
          {fieldsState?.primary_control === 'fanConnect' && (
            <Grid item className="section-1 calc-mode">
              <div className="calc-mode--wrapper mt-4">
                <InputPlus
                  handleChange={(value: any) => {
                    onChangeState('efdt', parseNumber(value));
                  }}
                  handleChangeError={(err: any) => {}}
                  disabled={false}
                  type={config.fields.efdt.type}
                  label={config.fields.efdt.label}
                  value={`${fieldsState?.efdt}`}
                  units={config.fields.efdt.units}
                  error={errors?.efdt}
                  name={'efdt'}
                />
              </div>
            </Grid>
          )}
          {fieldsState?.primary_control !== 'fanConnect' && (
            <Grid item className="section-1 calc-mode">
              <div className="calc-mode--wrapper mt-4">
                <InputPlus
                  handleChange={(value: any) => {
                    onChangeState('vcdt', parseNumber(value));
                  }}
                  handleChangeError={(err: any) => {}}
                  disabled={false}
                  type={config.fields.vcdt.type}
                  label={config.fields.vcdt.label}
                  value={`${fieldsState?.vcdt}`}
                  units={config.fields.vcdt.units}
                  error={errors?.vcdt}
                  name={'vcdt'}
                />
              </div>
            </Grid>
          )}
        </>
      )}

      <Grid item className="section-1 calc-mode">
        <div className="calc-mode--wrapper">
          <InputPlus
            handleChange={(value: any) => {
              onChangeState('mixt', parseNumber(value));
            }}
            handleChangeError={(err: any) => {}}
            disabled={false}
            type={config.fields.mixt.type}
            label={config.fields.mixt.label}
            value={`${fieldsState?.mixt}`}
            units={config.fields.mixt.units}
            error={errors?.mixt}
            name={'mixt'}
          />
        </div>
      </Grid>
      <Grid item className="section-1 calc-mode--op-h">
        <div className="hours-operation">
          <PeripheralOptional
            id="hroa"
            label="Hours of operation"
            value={fieldsState?.hroa ? fieldsState?.hroa : 0}
            options={[
              { value: 1, label: 'Yes' },
              { value: 0, label: 'No' },
            ]}
            onChange={(event: any) =>
              onChangeState('hroa', parseInt(event.target.value))
            }
          />
          {fieldsState?.hroa === 1 && (
            <RangeTime
              onChangeState={onChangeState}
              errors={errors}
              fieldsState={fieldsState}
              config={config}
            />
          )}
        </div>
      </Grid>
      <Grid item className="section-1 calc-mode">
        <div className="calc-mode--tz">
          <div className="container-timezone">
            <label className="inputLabel">Timezone</label>
            <div className="device-zone--select">
              <NativeSelect
                className="select"
                value={TIMEZONE[fieldsState.tmzn]?.label}
                onChange={handleSetTimezone}
              >
                {TIMEZONE.map((tm: any) => (
                  <option key={tm.label} value={tm.label}>
                    {tm.label}
                  </option>
                ))}
              </NativeSelect>
            </div>
          </div>
        </div>
      </Grid>

      <Grid item className="section-1 calc-mode">
        <div className="calc-mode--wrapper">
          <Input
            disabled
            handleChangeError={() => {}}
            handleChange={() => {}}
            type="text"
            label={config.fields.tmzn.label}
            labelStyles="calc-mode--curr-time"
            value={`${formatInTimeZone(
              new Date(),
              `${TIMEZONE[fieldsState.tmzn]?.timezone}`,
              'HH:mm',
            )}`}
            error={''}
            name={''}
          />
        </div>
      </Grid>
    </div>
  );
};

export default CalculatedFlow;
